import { Component, OnInit, Renderer2 } from "@angular/core";
import { environment } from "../environments/environment";
import { SplunkService } from "./_modules/dashboard/_service/splunk.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
  isOnline: boolean;
  constructor(
    private splunkService: SplunkService,
    private renderer: Renderer2
  ) {
    this.isOnline = navigator.onLine;
    this.appendGaTrackingCode();
    this.loadGoogleMapsKey();
    this.addChatBotScript();
  }
  private appendGaTrackingCode() {
    try {
      const gaScript = this.renderer.createElement("script");
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.google.GTagTrackingID}`;
      gaScript.async = true;
      this.renderer.appendChild(document.head, gaScript);

      const gtagScript = this.renderer.createElement("script");
      gtagScript.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.google.GTagTrackingID}');
        `;
      this.renderer.appendChild(document.head, gtagScript);
    } catch (err) {
      console.error("Error appending google analytics");
      console.error(err);
    }
  }

  ngOnInit() {
    let reportUrl = window.location.href;
    let logData = {
      logLevel: "Info",
      extraLog: "true",
      logDescription: "Has been redirect to app component page",
      logMessage: "url is:" + reportUrl
    };
    this.splunkService.splunkLog(logData);
    //splunk log end here//
  }

  private loadGoogleMapsKey(): void {
    const script = this.renderer.createElement('script');
    script.src=`https://maps.googleapis.com/maps/api/js?key=${environment.mapKey}&libraries=places`;
    script.type = "text/javascript";
    this.renderer.appendChild(document.body, script);
  }

  private addChatBotScript(): void {
    const script = this.renderer.createElement('script');
    script.src=`https://${environment.API.chatbotAPI}.c1conversations.io/widget-server-conversations/rest/resources/${environment.chatbotKey}/v2/distributable.js`;
    script.type = "text/javascript";
    this.renderer.appendChild(document.body, script);
  }
}

