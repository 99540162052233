import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Router, NavigationEnd, RouterState, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

declare var gtag: any;

@Injectable()
export class GaEventsService {
  constructor(public router: Router,
    @Inject(DOCUMENT) private document: Document) {
    this.router.events.subscribe(event => {
      try {
        if (environment.logToAnalytics.SendRoute) {
          if (typeof gtag === "function") {
            if (event instanceof NavigationEnd) {              
              const title = this.getTitle(this.router.routerState, this.router.routerState.root);
              gtag('event', 'page_view', {
                page_title:title,
                path_path: event.urlAfterRedirects,
                path_location: this.document.location.href
              });
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  private getTitle(state: RouterState, parent: ActivatedRoute): any {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title'])
      data.push(parent.snapshot.data['title']);

    if (state && parent && parent.firstChild)
      data.push(...this.getTitle(state, parent.firstChild));

    return data;
  }

  /**
   * @memberof GaEventsService
   */
  public errorCategory = {
    unhandledexception: "UnhandledException",
    others: "Others"
  };

  public TrackTiming(timingcategory: any, timingvar: string) {
    if (window.performance) {
      var timeSincePageLoad = Math.round(performance.now());
      gtag("event", "send", {
        hitType: "timing",
        timingCategory: timingcategory, //'JS Dependencies',
        timingVar: timingvar, //'load',
        timingValue: timeSincePageLoad // In milliseconds
      });
    }
  }

  /**
   * Emit google analytics event
   * Fire event example:
   * this.sendEvent("testCategory", "testAction", "testLabel", 10);
   * @param {string} eventCategory
   * @param {string} eventAction
   * @param {string} [eventLabel=null]
   * @param {number} [eventValue=null]
   * @memberof GaEventsService
   */
  public sendEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string,
    eventValue: number
  ) {
    if (environment.logToAnalytics.SendEvent) {
      if (eventAction.toLowerCase().indexOf("?vin") > -1) {
        let split = eventAction.split("=");
        split[1] = "#######";
        eventAction = split.join("=");
      }
      if (typeof gtag === "function") {
        gtag("event", eventCategory, {
          eventCategory: eventCategory,
          eventAction: eventAction,
          eventLabel: eventLabel,
          eventValue: eventValue ? eventValue : "NA"
        });
      }
    }
  }

  /**
   * Emit google analytics exception
   * @param {string} exDescription
   * @param {boolean} [exFatal]
   * @memberof GaEventsService
   */
  public sendException(exDescription: string, exFatal?: boolean) {
    if (environment.logToAnalytics.SendException) {
      if (typeof gtag === "function") {
        gtag("event", "exception", {
          exDescription: exDescription,
          exFatal: exFatal
        });
      } else {
        console.error("Google Analytics not loaded");
      }
    }
  }

  /**
   * @param {*} error
   * @param {string} URL
   * @param {string} exceptionType
   * @param {boolean} [fatal]
   * @memberof GaEventsService
   */
  public trackException(
    error: any,
    URL: string,
    exceptionType: string,
    fatal?: boolean
  ): void {
    if (fatal === undefined) {
      fatal = false;
    }
    let errorObj;
    // const user_email = this.authService.getDecodedIdToken().userName
    //     ? this.authService.getDecodedIdToken().userName
    //     : "";

    const currentdate = new Date();
    const datetime = `${currentdate.getDate()}/${currentdate.getMonth() +
      1}/${currentdate.getFullYear()}@${currentdate.getHours()}:${currentdate.getMinutes()}:${currentdate.getSeconds()}`;

    if (exceptionType === "UnhandledException") {
      // errorObj = StackTraceParser.parse(error);
    } else {
      errorObj = JSON.stringify(error, Object.getOwnPropertyNames(error));
    }

    // const description = {
    //     environment: environment.envName,
    //     timestamp: datetime,
    //     exceptionType: exceptionType,
    //     correlationId: this.splunkService.corelationID,
    //     identity: user_email,
    //     app: {
    //         request: {
    //             url: URL
    //         }
    //     },
    //     stackTrace: errorObj,
    //     userAgent: this.splunkService.browserInformation,
    // };
  }
}
