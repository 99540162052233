<mat-dialog-content>
    <div class="title-group">
        <h2>
            {{messageData.header}}
        </h2>
    </div>
    <div class="row gap-top" style="text-align: center;">
        {{messageData.body}}
    </div>
</mat-dialog-content>
<mat-dialog-actions style="float:right">
    <div class="row gap-top">
        <button type="button" class="btn-submit" (click)="onClose()">Ok</button>
    </div>
</mat-dialog-actions>