import {
  Component,
  Input,
  Output,
  forwardRef,
  EventEmitter,
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import * as moment from "moment-timezone";
import { DatepickerReportsService } from "src/app/_modules/dashboard/_service/datepicker-reports.service";
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatepickerReports),
  multi: true,
};

const noop = () => {};

/** @title Basic datepicker */
@Component({
  selector: "datepicker-reports",
  templateUrl: "datepicker-reports.component.html",
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class DatepickerReports implements ControlValueAccessor {
  public mask = {
    guide: false,
    showMask: true,
    // keepCharPositions : true,
    mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
  };
  @Input() title: string;
  @Input() type: string;
  @Input() disabledState: boolean;
  @Input() dateFrom: any = null;
  @Input() dateTo: any = null;
  @Input() ariaLabel: string = null;
  @Output()
  changeDate = new EventEmitter<string>();
  innerValue: any = "";
  showInlineError: string = "";
  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  constructor(private datepickerService: DatepickerReportsService) {}

  ngOnInit() {
    this.datepickerService.errorMessage.subscribe(
      (message) => (this.showInlineError = message)
    );
    this.datepickerService.fromVal.subscribe((message) => {});
    this.datepickerService.toVal.subscribe((message) => {});
  }
  //get accessor
  get value(): any {
    return this.innerValue;
  }

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }
  //Occured value changed from module
  writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onChange(event) {
    this.value = event;
    this.onBlur(event);
  }
  HasData(value: any) {
    if (
      typeof value === "undefined" ||
      value === null ||
      value === "" ||
      value.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  }
  todate(value) {
    try {
      if (value) {
        // value = moment(value).format("MM/DD/YYYY");
        // value = this.datePipe.transform(value, 'MM/dd/yyyy');

        if (!moment(value, "MM/DD/YYYY", true).isValid()) {
          value = "";
          this.value = "";
        } else {
          value = new Date(value);
          this.value = value;
        }
      } else {
        value = "";
        this.value = "";
      }
    } catch (ex) {
      this.value = "";
      value = "";
      //this.showInlineError = "Please enter valid date" ;
    }
    //this.onBlur();
  }

  onBlur(event) {
    let x = event.target ? event.target.value : event;
    let fromDate = this.dateFrom;
    let toDate = this.dateTo;
    this.todate(x);
    if (fromDate == "" || toDate == "") {
      this.dateFrom = null;
      this.dateTo = null;
    }

    this.showInlineError = "";
    this.onChangeCallback(this.innerValue);
    let hasError = false;
    if (this.type == "from") {
      fromDate = this.innerValue;
    } else {
      toDate = this.innerValue;
    }
    if (this.HasData(toDate) && this.HasData(fromDate)) {
      toDate = new Date(toDate);
      fromDate = new Date(fromDate);
      let timeDiff = Math.abs(fromDate.getTime() - toDate.getTime());
      let dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (toDate < fromDate) {
        this.showInlineError =
          "Start Date must be less than or equal to the End Date.";
        hasError = true;
      } else if (dayDifference > 365) {
        this.showInlineError =
          "Date Range must be less than or equal to 365 days.";
        hasError = true;
      }
    } else {
      if (toDate == "" || fromDate == "") {
        this.showInlineError = "Please enter valid Start and End Date.";
        hasError = true;
      }
    }

    if (hasError) {
      if (this.type == "from") {
        var msg = this.value ? this.value.toDateString() : "";
        this.datepickerService.updateFrom(msg);
      } else if (this.type == "to") {
        var msg = this.value ? this.value.toDateString() : "";
        this.datepickerService.updateTo(msg);
      }
    }
    console.log("inline" + this.showInlineError);
    this.datepickerService.updateMsg(this.showInlineError);
    this.changeDate.emit("complete");
  }
}
