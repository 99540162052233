import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private isLoading: boolean = false;

  constructor() { }

  setLoader(flag: boolean): void {
    this.isLoading = flag;
  }

  getLoader(): boolean {
    return this.isLoading;
  }
}
