import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class SplunkService {
  corelationID: any =
    new Date().valueOf() + "" + Math.floor(100000 + Math.random() * 900000);
  browserInformation = window.navigator.userAgent;
  constructor() {}

  public splunkLog(logData): any {
    try {
      if (environment.hashPIIData) {
        let hashvariables = ["firstname", "lastname", "customername", "vin"];
        let stringLogmessage = JSON.stringify(logData.logMessage);
        let split = [];
        hashvariables.forEach(ele => {
          if (
            stringLogmessage.toLowerCase().indexOf(ele) > -1 &&
            stringLogmessage.toLowerCase().indexOf("?vin") === -1
          ) {
            split = logData.logMessage.split(":");
            for (let i = 0; i < split.length; i++) {
              if (split[i].toLowerCase().indexOf(ele) !== -1) {
                let z = split[i + 1].split(",");
                z[0] = "#######";
                split[i + 1] = z.join(",");
              }
            }
            logData.logMessage = split.join(":");
          }
        });
        if (stringLogmessage.toLowerCase().indexOf("?vin") > -1) {
          split = logData.logMessage.split("=");
          let a = split[1].split(",");
          a[0] = "#######";
          split[1] = a.join(",");
          logData.logMessage = split.join("=");
        }
      }
    } catch (ex) {
      console.log(ex);
    }
    // console.log(logData.logMessage);

    let splunkPayLoad = {
      event: {
        environment: environment.envName,
        application: "AMDashboard_UI",
        clientIP: "435345345",
        Body: {
          extraLog: logData.extraLog,
          logLeve: logData.logLevel,
          logDescription: logData.logDescription,
          logMessage: logData.logMessage,
          browserDetails: this.browserInformation,
          correlationid: this.corelationID
        }
      }
    };
    if (
      splunkPayLoad.event.Body.extraLog == "true" &&
      environment.extraLoggs === false
    ) {
    } else {
      let Authorization = environment.splunkAuth;
      let xhr = new XMLHttpRequest();
      xhr.open("POST", environment.API.splunk);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", Authorization);
      xhr.send(JSON.stringify(splunkPayLoad));
    }
  }
}
