import { Injectable } from "@angular/core";
import { CodeAuth } from "agero-js-identity";
import { environment } from "../../../../environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { SplunkService } from "./splunk.service";
import { GaEventsService } from "./ga-events.service";
import * as querystring from "querystring";
import { AMActivityService, ActivityService } from "../activity/_service";
import { LOCALSTORAGE_KEYS, SESSIONSTORAGE_KEYS } from "../activity/_models";
import { WebsocketService } from "./websocket.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private window: Window;
  constructor(
    public dialog: MatDialog,
    private splunkService: SplunkService,
    private gaEventsService: GaEventsService,
    private activityService: ActivityService,
    private amActivityService: AMActivityService,
    private websocketService: WebsocketService
  ) {
    this.window = window;
  }
  public codeAuth = new CodeAuth({
    clientId: environment.ageroAuthConfig.clientId,
    appWebDomain: environment.ageroAuthConfig.appWebDomain,
    redirectUriSignIn: environment.ageroAuthConfig.redirectUriSignIn,
    redirectUriSignOut: environment.ageroAuthConfig.redirectUriSignOut,
    apiFullUrl: environment.ageroAuthConfig.apiFullUrl,
    refreshTimeoutOffset: environment.ageroAuthConfig.refreshTimeoutOffset,
    activityTimeout: environment.ageroAuthConfig.activityTimeout,
    useMultiTenantApi: environment.ageroAuthConfig.useMultiTenant,
    loginCallback: (value) => {
      console.log("I logged in!" + value);
      let logData = {
        /*send data to splunk*/
        logLevel: "Info",
        extraLog: "false",
        logDescription: "Login Succsessful",
        logMessage: value ? JSON.stringify(value) : "NA",
      };
      this.splunkService.splunkLog(logData);
    },
    refreshCallback: (value) => {
      console.log("I refreshed my token!" + value);
      let logData = {
        /*send data to splunk*/
        logLevel: "Info",
        extraLog: "true",
        logDescription: "I refreshed my token! callback",
        logMessage: value ? JSON.stringify(value) : "NA",
      };
      this.splunkService.splunkLog(logData);
      /*send to google analytics*/
      this.gaEventsService.sendEvent(
        "Refresh Token ",
        "I refreshed my token!",
        "Refresh Token",
        5
      );
    },
    signOutCallback: (value) => {
      if (sessionStorage.getItem(SESSIONSTORAGE_KEYS.AM_ACTIVITY_USER_READY))
        this.amActivityService.onLogoutXHR();

      console.log("I logged out!" + value);
      let logData = {
        /*send data to splunk*/
        logLevel: "Info",
        extraLog: "true",
        logDescription: "I logged out! callback",
        logMessage: value ? JSON.stringify(value) : "NA",
      };
      this.splunkService.splunkLog(logData);
      localStorage.removeItem("selectedCaseId");
      localStorage.removeItem("currentProgramCode");
      localStorage.removeItem(LOCALSTORAGE_KEYS.EXTENSION_NUMBER);
      localStorage.removeItem(LOCALSTORAGE_KEYS.EMAIL_ADDRESS);
      localStorage.removeItem(LOCALSTORAGE_KEYS.PHONE_STATUS);
      localStorage.removeItem(LOCALSTORAGE_KEYS.READONLY_USER);
      localStorage.removeItem(LOCALSTORAGE_KEYS.AM_ACTIVITY_LOGGED_IN_USER);
    },
    errorCallback: (err) => {
      console.log("Error occurred!" + err);
      let logData = {
        /*send data to splunk*/
        extraLog: "false",
        logLevel: "Critical",
        logDescription: "Error occured",
        logMessage: "error is:" + err ? JSON.stringify(err) : "NA",
      };
      this.splunkService.splunkLog(logData);
    },
  });

  public logoutActivity(): void {
    const statusRequest: any = {
      emailAddress: localStorage.getItem(LOCALSTORAGE_KEYS.EMAIL_ADDRESS),
      phoneStatus: 'ctc_Logout',
      phoneExtension: localStorage.getItem(LOCALSTORAGE_KEYS.EXTENSION_NUMBER)
    }
    this.activityService.updatePhoneStatus(statusRequest).subscribe(() => {
      let logData = {
        logLevel: "Info",
        extraLog: `false`,
        logDescription: `Log out successfully from CTI`,
        logMessage: "Log out successfully from CTI",
      };
      this.splunkService.splunkLog(logData);
    },
      (err: any) => {
        let logData = {
          logLevel: "Info",
          extraLog: `${JSON.stringify(err)}`,
          logDescription: `Phone status could not be set as Offline.`,
          logMessage: err.error?.error?.message,
        };
        this.splunkService.splunkLog(logData);
      });
    localStorage.removeItem(LOCALSTORAGE_KEYS.EMAIL_ADDRESS);
    localStorage.removeItem(LOCALSTORAGE_KEYS.PHONE_STATUS);
    localStorage.removeItem(LOCALSTORAGE_KEYS.READONLY_USER);
  }

  getSession() {
    return this.codeAuth.getSession();
  }
  parseRedirect(callBack: Function) {
    this.codeAuth.parseRedirect(callBack);
  }
  login() {
    this.codeAuth.getSession();
    localStorage.removeItem(LOCALSTORAGE_KEYS.EXTENSION_NUMBER);
    localStorage.removeItem(LOCALSTORAGE_KEYS.EMAIL_ADDRESS);
    localStorage.removeItem(LOCALSTORAGE_KEYS.PHONE_STATUS);
    localStorage.removeItem(LOCALSTORAGE_KEYS.READONLY_USER);
    sessionStorage.removeItem(SESSIONSTORAGE_KEYS.AM_ACTIVITY_USER_READY);
    sessionStorage.removeItem(SESSIONSTORAGE_KEYS.AM_ACTIVITY_OPEN);
    localStorage.removeItem(LOCALSTORAGE_KEYS.AM_ACTIVITY_LOGGED_IN_USER);
  }
  signOut() {
    if (localStorage.getItem(LOCALSTORAGE_KEYS.PHONE_STATUS))
      this.logoutActivity();

    if (sessionStorage.getItem(SESSIONSTORAGE_KEYS.AM_ACTIVITY_USER_READY))
      this.amActivityService.onLogoutXHR();

    this.websocketService.closeConnection();
    this.codeAuth.signOut();
    localStorage.removeItem(LOCALSTORAGE_KEYS.AM_ACTIVITY_LOGGED_IN_USER);
  }
  isUserSignedIn(): boolean {
    return this.codeAuth.isUserSignedIn();
  }
  getDecodedIdToken() {
    const decodedToken = this.codeAuth.getDecodedIdToken();
    return decodedToken;
  }

  getRawIdToken() {
    const idToken = this.codeAuth.getRawIdToken();
    return idToken;
  }
  refreshToken(refreshCallBack: any) {
    return this.codeAuth.refreshToken(refreshCallBack);
  }
  activityTimer() {
    return this.codeAuth.resetActivityTimer();
  }

  checkLogin() {
    if (this.isUserSignedIn()) {
      let logData = {
        /*send data to splunk*/
        logLevel: "Info",
        extraLog: "true",
        logDescription: "Check for isUserSignedIn",
        logMessage: "isUserSignedIn is successful - true",
      };
      this.splunkService.splunkLog(logData);
      return true;
    } else {
      let logData = {
        /*send data to splunk*/
        logLevel: "Info",
        extraLog: "true",
        logDescription: "Check for isUserSignedIn",
        logMessage:
          "isUserSignedIn is successful - false, that leads to user to login page",
      };
      this.splunkService.splunkLog(logData);
      /*this is only for deeplink access after session expired*/
      try {
        const qs = querystring.parse(this.window.location.search.substring(1));
        if (Object.keys(qs).length > 0) {
          localStorage.setItem(
            "currentProgramCode",
            JSON.stringify(qs.client.toString().replace(/"/g, "")).replace(
              /"/g,
              ""
            )
          );
          localStorage.setItem(
            "selectedCaseId",
            JSON.stringify(qs.id.toString().replace(/"/g, "")).replace(/"/g, "")
          );
        }
      }
      catch (err) {
        let logData = {
          /*send data to splunk*/
          logLevel: "Error",
          extraLog: "true",
          logDescription: "Error in deep link url in checkLogin",
          logMessage:
            `URL Query String: ${this.window.location.search} , Error: ${err}`,
        };
        this.splunkService.splunkLog(logData);

      } /*end here*/
      this.login();
    }
  }
}
