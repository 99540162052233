import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/modules/shared.module";
import { CoreModule } from "./core/core.module";
import { AppComponent } from "./app.component";
import { LogoutComponent } from "./_modules/logout/logout.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule } from "@angular/common/http";
import { IdentityGuard } from "./_modules/dashboard/_guards/identity.guard";
import { LogoutGuard } from "./_modules/dashboard/_guards/logout.guard";
import { httpInterceptorProviders } from "./shared/_interceptor";
import { SessionTimeoutConfirmationDialogComponent } from "./_modules/dashboard/session-timeout/session-timeout-confirmation-dialog.component";
import { SplunkService } from "./_modules/dashboard/_service/splunk.service";
import { CallBackComponent } from "./_modules/callback/callback.component";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { IpFilterService } from "./_modules/dashboard/_service/ipfilter.service";
import { UserActivityService } from "./_modules/dashboard/_service/userinactivity.service";
import { ImpersonateService } from "./_modules/dashboard/_service/impersonate.service";
import { GaEventsService } from "./_modules/dashboard/_service/ga-events.service";
import { ConfirmationDialogComponent } from './shared/_dialogs/confirmation-dialog/confirmation-dialog.component';


declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

@NgModule({
  imports: [
    BrowserModule,
    // NoopAnimationsModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    SharedModule.forRoot(),
    CoreModule,
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  declarations: [
    AppComponent,
    LogoutComponent,
    CallBackComponent,
    SessionTimeoutConfirmationDialogComponent,
    ConfirmationDialogComponent
  ],
  entryComponents: [SessionTimeoutConfirmationDialogComponent],
  providers: [
    httpInterceptorProviders,
    IdentityGuard,
    LogoutGuard,
    IpFilterService,
    UserActivityService,
    SplunkService,
    GaEventsService,
    ImpersonateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
