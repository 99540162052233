import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class DatepickerReportsService {
  private errMsg = new BehaviorSubject(null);
  private from = new BehaviorSubject(null);
  private to = new BehaviorSubject(null);

  errorMessage = this.errMsg.asObservable();
  fromVal = this.from.asObservable();
  toVal = this.to.asObservable();

  constructor() {}
  updateMsg(msg: string) {
    this.errMsg.next(msg);
  }
  updateFrom(msg: string) {
    this.from.next(msg);
  }
  updateTo(msg: string) {
    this.to.next(msg);
  }
}
