import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phoneFormat"
})
export class PhoneFormatPipe implements PipeTransform {
  transform(val) {
    if (val) {
      var val = val
        .toString()
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return (val = val[3]
        ? !val[2]
          ? val[1]
          : "(" + val[1] + ") " + val[2] + (val[3] ? "-" + val[3] : "")
        : !val[2]
        ? val[1]
        : val[1] + "-" + val[2]);
    } else {
      return "";
    }
  }
}
