import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateSort'
})
export class DateSortPipe implements PipeTransform {

  transform(values: any[], key: string, order: 'asc' | 'desc' = 'asc'): any[] {
    if (!values || values.length === 0 || !key) {
      return values;
    }

    return values.sort((a: any, b: any) => {
      const dateA = new Date(a[key]);
      const dateB = new Date(b[key]);
      return order === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
    })
  }

}
