import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-messages-dialog',
  templateUrl: './messages-dialog.component.html'
})
export class MessagesDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public messageData: any,
    private dialogRef: MatDialogRef<MessagesDialogComponent>
  ) { }


  /**
   * Description - method is used to close the dialog box
   */
  public onClose(): void {
    this.dialogRef.close();
  }

}
