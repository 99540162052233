// Angular
import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { SplunkService } from "../_service/splunk.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-session-timeout-confirmation",
  templateUrl: "session-timeout-confirmation-dialog.html"
})
export class SessionTimeoutConfirmationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SessionTimeoutConfirmationDialogComponent>,
    private splunkService: SplunkService,
    private router: Router
  ) {}
  ngOnInit() {
    let reportUrl = this.router.url;
    let logData = {
      logLevel: "Info",
      extraLog: "false",
      logDescription: "You are in the 25 mins session-timeout popup page",
      logMessage: "url is:" + reportUrl
    };
    this.splunkService.splunkLog(logData);
  }
}
