import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LOCALSTORAGE_KEYS } from '../_models';


@Injectable({
  providedIn: 'root'
})
export class ControlBuilderService {
  public activityQuestions;
  private currentAddedElement;
  public activityFormGroup: FormGroup = new FormGroup({});
  
  public activitySubscription = new Subscription();
  private crntControlAddedOrUpdate: boolean = false;
  private readonly VALID_CONTROLS_TYPES = ["text", "textarea", "link", "password", "autocompleteAddress"];

  constructor() { }


  public resetNewActivity(): void {
    this.activityQuestions = null;
    this.currentAddedElement = null;
    this.activityFormGroup = new FormGroup({});
    this.crntControlAddedOrUpdate = false;
    this.activitySubscription = new Subscription();
  }


  /**
   * method to add all parent controls
   */
  public populateQuestionnaireControls(): void {
    localStorage.removeItem(LOCALSTORAGE_KEYS.CURRENT_UPDATED_CONTROL);
    for (let control of this.activityQuestions) {
      if (!control.parentControls || control.parentControls.length == 0) {
        const { key, formControl } = this.addFormGroupControl(control);
        this.addQuesControl(key, formControl, control);
      }
    }
    this.updateChildrenControl();
  }

  /**
   * adding conditional value, validation, etc.
   */
  public addFormGroupControl(control, childCondition?: any): any {
    let validators = null, value = null, isEnabled: boolean;
    const updateOn = this.getValueChangeEvent(control.type);

    if (control.type === 'script')
      isEnabled = true;

    if (childCondition) {
      if (childCondition.validators && control.type !== 'script') {
        validators = this.addValidators(childCondition.validators);
        isEnabled = childCondition.validators.isEnabled;
      }
      value = childCondition.value;
    }
    else {
      if (control.validators && control.type !== 'script') {
        validators = this.addValidators(control.validators);
        isEnabled = control.validators.isEnabled;
      }
      value = control.value;
    }

    const formControl = new FormControl(
      { value: value, disabled: !isEnabled },
      { updateOn, validators }
    );

    formControl.metadata = {
      elementType: control.type,
      key: control.key,
      label: control.label,
      initialValue: value,
      template: control
    }
    return { key: control.key, formControl: formControl };
  }

  /**
   * method to register change event for form control
   */
  private getValueChangeEvent(type: string): any {
    let updateOn;
    switch (type) {
      case "dropdown":
      case "checkbox":
      case "date":
      case "radio":
      case "callback":
      case "autocompleteAddress":
        updateOn = "change";
        break;
      case "textarea":
      case "textbox":
      case "text":
      default:
        updateOn = "blur";
        break;
    }
    return updateOn;
  }


  /**
   * to add in-built validators
   */
  private addValidators(validations: any) {
    const customValidators = [];
    if (validations) {
      for (let [key, value] of Object.entries(validations)) {
        if (value) {
          switch (key) {
            case "isRequired":
              customValidators.push(Validators.required);
              break;
            case "minLength":
              customValidators.push(
                Validators.minLength(parseInt(value.toString()))
              );
              break;
            case "maxLength":
              customValidators.push(
                Validators.maxLength(parseInt(value.toString()))
              );
              break;
            case "pattern":
              customValidators.push(Validators.pattern(value.toString()));
              break;
            case "email":
              customValidators.push(Validators.email);
              break;
          }
        }
      }
    }
    return customValidators;
  }



  /**
   * method to add control to Questionnaire section
   */
  private addQuesControl(key: string, formControl: AbstractControl, control: any): void {
    this.crntControlAddedOrUpdate = true;
    if (!this.activityFormGroup.get(key)) {
      this.currentAddedElement = this.currentAddedElement ?? key;
      this.activityFormGroup.addControl(key, formControl);
    }
  }



  /**
   * returns question control by key name
   */
  public getQuesControl(key: string): AbstractControl {
    return this.activityFormGroup.get(key);
  }


  /**
   * mehtod to udpate form control value, condition, state and validations
   */
  public updateValidators(crntControl: AbstractControl, controlItem: any): void {
    this.crntControlAddedOrUpdate = true;
    this.activityFormGroup.patchValue({ key: controlItem.value });
    crntControl.clearValidators();

    controlItem.validators.isEnabled ? crntControl.enable() : crntControl.disable();
    crntControl.setValidators(this.addValidators(controlItem.validators));
  }


  /**
   * Removes form group control and summary row from summary section
   */
  private removeFormGroupControl(name: string): void {
    if (this.getQuesControl(name)) {
      this.activityFormGroup.removeControl(name);
    }
  }


  /**
   * method to update value and current control key in localStorage
   */
  public updateQuesCtrlValueAndKey(key: string, value: any): void {
    localStorage.setItem(LOCALSTORAGE_KEYS.CURRENT_UPDATED_CONTROL, key);
    this.getQuesControl(key)?.setValue(value);
  }


  /**
   * Updates form control before removing (to fix a issue)
   */
  private updateAndRemove(name: string) {
    if (this.getQuesControl(name)) {
      this.getQuesControl(name)?.enable();
      this.activityFormGroup.removeControl(name);
    }
  }


  /**
   * checks whether value is matched with the existing form control (array and string comparision)
   */
  private isValueMatched(crntParentControl: AbstractControl, condition: any): boolean {
    return Array.isArray(crntParentControl.value) ? crntParentControl.value.includes(condition) : crntParentControl.value == condition;
  }


  /**
   * method to identify next control and update/add/remove form controls
   */
  private updateChildrenControl(): void {
    this.activitySubscription?.unsubscribe();
    const key = localStorage.getItem(LOCALSTORAGE_KEYS.CURRENT_UPDATED_CONTROL);
    let crntControlKey: string = null;
    const childControls = JSON.parse(JSON.stringify(this.activityQuestions.filter((x) => x.parentControls && x.parentControls.length > 0)));

    let isMatched: boolean = false;
    const isParentControlExists = childControls.some(x => x.key == key);
    for (let control of childControls) {
      if (key) {
        if (isParentControlExists) {
          if (key == control.key) {
            isMatched = true;
            continue;
          }
          if (!isMatched) {
            continue;
          }
          this.updateAndRemove(control.key);
        }
        else {
          this.updateAndRemove(control.key);
        }
      }
    }

    isMatched = false;
    for (let crntControlItem of childControls) {
      if (key && isParentControlExists) {
        if (key == crntControlItem.key) {
          isMatched = true;
          continue;
        }
        if (!isMatched) {
          continue;
        }
      }

      if (!(crntControlItem.conditions && crntControlItem.conditions.length > 0)) {
        if (crntControlItem.parentControls.some(item => this.getQuesControl(item))) {
          const { key, formControl } = this.addFormGroupControl(crntControlItem);
          this.addQuesControl(key, formControl, crntControlItem);
        }
        continue;
      }

      if (!this.crntControlAddedOrUpdate && crntControlKey) {
        this.removeFormGroupControl(crntControlKey);
      }

      crntControlKey = crntControlItem.key;
      this.crntControlAddedOrUpdate = false;

      for (let conditionObj of crntControlItem.conditions) {
        const parentControl = this.getQuesControl(conditionObj.parent);
        if (this.crntControlAddedOrUpdate)
          break;

        if (parentControl) {
          const crntControl = this.activityFormGroup.get(crntControlItem.key);
          const hasMultiCondition = conditionObj.multiCondition && conditionObj.multiCondition.length > 0;
          const currentValidators = { value: crntControl?.value, validators: conditionObj?.validators };
          const conditionalValidators = { value: conditionObj.value, validators: conditionObj?.validators };

          if (hasMultiCondition) {
            let isMultiCondAllowed: boolean = true;
            for (let childCond of conditionObj.multiCondition) {
              const crntParentControl = this.getQuesControl(childCond.key);

              if (!(crntParentControl && this.isValueMatched(crntParentControl, childCond.condition))) {
                isMultiCondAllowed = false;
                break;
              }
            }

            if (isMultiCondAllowed) {
              const { key, formControl } = this.addFormGroupControl(crntControlItem, conditionalValidators);
              this.addQuesControl(key, formControl, crntControlItem);
              break;
            }
          }
          else {
            this.addAndUpdateControl(crntControlItem, conditionObj, crntControl, parentControl, currentValidators, conditionalValidators);
          }
        }
        else
          this.removeFormGroupControl(crntControlItem.key);
      }
    }
    // this.scrollBottom();
    this.currentAddedElement = null;
    this.onQuestionnaireFGChange();
  }


  /**
   * method to add and update control
   */
  private addAndUpdateControl(crntControlItem, conditionObj, crntControl, parentControl, currentValidators, conditionalValidators): void {
    const elementType: string = crntControlItem.type;
    const parentControlType: string = parentControl.metadata['elementType'];
    const isConditionNullOrEmpty: boolean = Array.isArray(conditionObj.condition) ? conditionObj.condition.some(item => !item) : !conditionObj.condition;

    if (isConditionNullOrEmpty && [null, undefined, ""].includes(parentControl.value)) {
      if (crntControl) {
        this.updateValidators(crntControl, conditionalValidators);
      }
      else {
        const { key, formControl } = this.addFormGroupControl(crntControlItem, conditionalValidators);
        this.addQuesControl(key, formControl, crntControlItem);
      }
    }
    else {
      let isConditionMatched: boolean = false;
      if (elementType == 'script') {
        isConditionMatched = this.isValueMatched(parentControl, conditionObj.condition);
      }
      else if (this.VALID_CONTROLS_TYPES.includes(parentControlType)) {
        isConditionMatched = parentControl.valid && this.isValueMatched(parentControl, conditionObj.condition);
      }
      else if (this.isValueMatched(parentControl, conditionObj.condition)) {
        isConditionMatched = true;
      }

      if (isConditionMatched) {
        if (crntControl) {
          this.updateValidators(crntControl, conditionalValidators);
        }
        else {
          const { key, formControl } = this.addFormGroupControl(crntControlItem, conditionalValidators);
          this.addQuesControl(key, formControl, crntControlItem);
        }
      }
    }
  }


  /**
   * method to update children Controls on change in any control
   */
  public onQuestionnaireFGChange(): void {
    this.activitySubscription = this.activityFormGroup.valueChanges.subscribe(() => {
      this.updateChildrenControl();
    });
  }
}
