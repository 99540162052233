import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "datePickerFormat"
})
export class DatePickerFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(value) {
    if (value == "") {
      value = null;
      return "";
    }
    if (value) {
      if (value == "Invalid Date" || value == "Invalid date") {
        value = null;
        return "";
      }
      return this.datePipe.transform(new Date(value), "MM/dd/yyyy");
    }
  }
}
