import { ModuleWithProviders, NgModule } from "@angular/core";
import { MaterialModule } from "./material.module";
import { Safe } from "../../shared/_pipes/safe.pipe";
import { PhoneFormatPipe } from "../../shared/_pipes/phone-format.pipe";
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from "../../shared/_pipes/date.adapter";
import { DateFormatPipe } from "../../shared/_pipes/date-format.pipe";
import { DisableControlDirective } from "../../shared/_pipes/disable-control.directive";
import { DatePickerFormatPipe } from "../../shared/_pipes/datepicker-format.pipe";
import { SortPipe } from "../../shared/_pipes/sort.pipe";
import { ToNumberPipe } from "../_pipes/tonumber.pipe";
import { DatepickerReports } from "src/app/_modules/dashboard/reports/shared/component/datepicker-reports/datepicker-reports.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TextMaskModule } from "angular2-text-mask";
import { DatepickerReportsService } from "src/app/_modules/dashboard/_service/datepicker-reports.service";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MessagesDialogComponent } from "../_dialogs/messages-dialog/messages-dialog.component";
import { UtcToEstPipe } from "../_pipes/utc-to-est.pipe";
import { DateSortPipe } from "../_pipes/date-sort.pipe";

@NgModule({
  imports: [MaterialModule, FormsModule, ReactiveFormsModule, TextMaskModule],
  declarations: [
    DisableControlDirective,
    Safe,
    PhoneFormatPipe,
    AppDateAdapter,
    DateFormatPipe,
    DatePickerFormatPipe,
    SortPipe,
    ToNumberPipe,
    DatepickerReports,
    MessagesDialogComponent,
    UtcToEstPipe,
    DateSortPipe
  ],
  exports: [
    MaterialModule,
    SortPipe,
    Safe,
    PhoneFormatPipe,
    AppDateAdapter,
    DateFormatPipe,
    DatePickerFormatPipe,
    DisableControlDirective,
    ToNumberPipe,
    DatepickerReports,
    MessagesDialogComponent,
    UtcToEstPipe,
    DateSortPipe
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        DatepickerReportsService,
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
      ],
    };
  }
}
