import { Injectable } from '@angular/core';

import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { environment } from 'src/environments/environment';

import { SplunkService } from './splunk.service';
import { LOCALSTORAGE_KEYS } from '../activity/_models';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  public websocket: WebSocketSubject<any>;
  public webSocket$: Observable<any>;
  public isWebSocketOpen: boolean = false;
  public messageInterval: any;
  public webSocketSubscription: Subscription = new Subscription();

  constructor(private splunkService: SplunkService) { }

  private setupWSConnection(urlEndpoint: string): WebSocketSubject<any> {
    const authToken = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.AUTH_TOKEN));
    const userName = authToken?.userName;
    const wsURL = environment.API.websocket_URL + `?header=${urlEndpoint}`;
    return webSocket({
      url: wsURL,
      openObserver: {
        next: () => {
          this.isWebSocketOpen = true;
          this.webSocketSubscription = new Subscription();

          const description = `WebSocket connected for Agent: ${userName}`;
          const message = `WebSocket connection has been established.`;
          this.addSplunkMessage(description, message, "Info");
        }
      },
      closeObserver: {
        next: () => {
          const description = `WebSocket connection closed for Agent: ${userName}`;
          const message = `WebSocket connection has been closed.`;
          this.addSplunkMessage(description, message, "Info");
        }
      },
      // deserializer: (events) => { return { type: events.type, data: events.data } }
    });
  }

  public connectWebsocket(urlEndpoint: string): void {
    if (!this.isWebSocketOpen) {
      this.websocket = this.setupWSConnection(urlEndpoint);
      this.recheckConnection();
      this.webSocket$ = this.websocket.pipe(
        tap(msg => {
          const description = `New message has been received via WebSocket.`;
          const message = `New message has been received via WebSocket. Message: ${JSON.stringify(msg)}`;
          this.addSplunkMessage(description, message, "Info");
        }),
        catchError(err => {
          this.isWebSocketOpen = false;
          const description = `An error has encountered in WebSocket`;
          const message = `Error in WebSocket connection: ${err}`;
          this.addSplunkMessage(description, message);
          return throwError(err);
        })
      );
    }
    else {
      const authToken = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.AUTH_TOKEN));
      const userName = authToken?.userName;

      const description = `WebSocket connection is already alive for Agent: ${userName}`;
      const message = `WebSocket connection is already opened`;
      this.addSplunkMessage(description, message, "Info");
    }
  }

  public sendMessage(data: any) {
    this.websocket?.next(data);
  }

  public closeConnection(): void {
    this.isWebSocketOpen = false;
    clearInterval(this.messageInterval);
    if (this.websocket) {
      this.websocket.complete();
    }
  }

  public recheckConnection(): void {
    clearInterval(this.messageInterval);
    this.messageInterval = setInterval(() => {
      this.sendMessage({ action: 'sendMessage', data: { type: 'To keep connection alive' } });
    }, 9 * 60000);
  }

  private addSplunkMessage(description: string, message: string, logLevel: string = 'Error'): void {
    let logData = {
      logLevel: logLevel,
      extraLog: "false",
      logDescription: description,
      logMessage: message,
    };
    this.splunkService.splunkLog(logData);
  }
}
