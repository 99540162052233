import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: 'utcToEst'
})
export class UtcToEstPipe implements PipeTransform {
  constructor() { }

  transform(value: unknown, format: string): unknown {
    if (!value)
      return null;

    const utcMoment = moment.utc(value);
    return utcMoment.tz("America/New_York").format(format ?? "MM/DD/YYYY");
  }

}
