<mat-dialog-content class="page-create-request items-inline">
  <!-- <div class="dialog-exit">
      <button type="button" [mat-dialog-close]="true" class="btn-x">&times;</button>
  </div> -->
  <div class="title-group">
    <h2>Session Timed Out</h2>
  </div>
  <div class="row items-inline gap-top">
    Your session is going to time out due to inactivity. If you hit OK - you
    will not be logged out. If you do not hit OK you will be logged out.
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="button" class="btn-complete-upload" [mat-dialog-close]="true">
    Ok
  </button>
</mat-dialog-actions>
