import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public messageData: any,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) { }

  /**
   * Description - method is used to confirm
   */
  public onContinue() {
    this.dialogRef.close('continue');
  }

  /**
   * Description - method is used to close the dialog box
   */
  public onClose(): void {
    this.dialogRef.close();
  }

}
