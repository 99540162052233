import { Component, OnInit } from "@angular/core";
import { AuthService } from "../dashboard/_service/authentication.service";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { IpFilterService } from "../dashboard/_service/ipfilter.service";
import { SplunkService } from "../dashboard/_service/splunk.service";
import { ActivatedRoute } from "@angular/router";
import { GaEventsService } from "../dashboard/_service/ga-events.service";

@Component({
  selector: "callback",
  templateUrl: "./callback.component.html",
})
export class CallBackComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private ipFilterService: IpFilterService,
    private splunkService: SplunkService,
    private route: ActivatedRoute,
    private _ga: GaEventsService
  ) {}
  private ipList = new BehaviorSubject(null);
  ipList$ = this.ipList.asObservable();

  ngOnInit() {
    this.authorizeUser();
  }

  private authorizeUser() {
    if (this.authService.isUserSignedIn()) {
      /*prevent the user to goback to callback page after succesfull login*/
      let logData = {
        /*send data to splunk*/
        extraLog: "true",
        logLevel: "Info",
        logDescription: "User is signIn already",
        logMessage: "So Redirect to dashboard",
      };
      this.splunkService.splunkLog(logData);
      this.router.navigateByUrl("/dashboard/home");
    } else {
      this.generateSession();
    }
  }

  private generateSession() {
    /* generate session for user */
    let callbackCode = this.route.snapshot.queryParamMap.get("code");
    this.authService.parseRedirect(() => {
      let logData = {
        /*send data to splunk*/
        extraLog: "true",
        logLevel: "Info",
        logDescription: "parseRedirect callback happend",
        logMessage: "callback code:" + callbackCode,
      };
      this.splunkService.splunkLog(logData);
      console.log("parse redirect completed");
      this.ipfilterValidation();
    });
  }

  /**
   * check if deeplink access after session expired
   * @memberof callBackComponent
   * @private
   */
  private isDeepLinkPresent(): void {
    const caseId = localStorage.getItem("selectedCaseId")
      ? localStorage.getItem("selectedCaseId") !== "undefined"
        ? localStorage.getItem("selectedCaseId")
        : null
      : null;
    const client = localStorage.getItem("currentProgramCode")
      ? localStorage.getItem("currentProgramCode") !== "undefined"
        ? localStorage.getItem("currentProgramCode")
        : null
      : null;
    if (caseId && client) {
      try {
        let isCRMId = caseId.indexOf("-");
        if (isCRMId == -1) {
          this.router.navigateByUrl(
            `/dashboard/reports/caseDetails?id=${caseId.replace(
              /"/g,
              ""
            )}&client=${client.replace(/"/g, "")}`
          );
        } else {
          let removeIdQuotation = caseId.indexOf('"') >= 0;
          let removeClientQuotation = client.indexOf('"') >= 0;
          if (removeIdQuotation && removeClientQuotation) {
            this.router.navigateByUrl(
              `/dashboard/reports/caseDetails?id=${JSON.parse(
                caseId
              )}&client=${JSON.parse(client)}`
            );
          } else {
            this.router.navigateByUrl(
              `/dashboard/reports/caseDetails?id=${caseId}&client=${client}`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.router.navigateByUrl("/dashboard");
    }
  }

  private ipfilterValidation() {
    /* check is user has valid ip address to access */
    let token = this.authService.getRawIdToken();
    let logData = {
      /*send data to splunk*/
      extraLog: "true",
      logLevel: "Info",
      logDescription: "Before Calling IP filter api",
      logMessage: token,
    };
    this.splunkService.splunkLog(logData);
    if (token) {
      this.ipFilterService.getIpFilter().subscribe(
        (data) => {
          if (data) {
            if (!data.ipAddressAllowed) {
              var ip = "";
              if (data.ipAddress) {
                let iplists = data.ipAddress;
                this.ipFilterService.ipList.next(iplists);
                localStorage.clear();
                this.router.navigate(["error"]);
                ip = data.ipAddress;
              } else {
                this.router.navigate(["not-found"]);
                ip = "Not available from api";
                localStorage.clear();
              }
              let logData = {
                /*send data to splunk*/
                extraLog: "true",
                logLevel: "Info",
                logDescription: "Invalid IP filter",
                logMessage: ip,
              };
              this.splunkService.splunkLog(logData);
            } else {
              this.isDeepLinkPresent();
              this._ga.sendEvent(
                "Login",
                "successfullyLogin",
                this.authService.getDecodedIdToken().programCode,
                1
              );
            }
          } else {
            localStorage.clear();
          }
        },
        (error) => {
          console.log("ipfilter error" + error);
          this.router.navigateByUrl("/not-found");
          localStorage.clear();
        }
      );
    }
  }
}
