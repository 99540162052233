import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { SplunkService } from "../../_modules/dashboard/_service/splunk.service";
import { AuthService } from "src/app/_modules/dashboard/_service/authentication.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private splunkService: SplunkService,
    private authService: AuthService
  ) {
    //
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.authService.isUserSignedIn()) {
      var hasAccountId = request.headers.has("hasAccountId");
      if (!request.headers.has("noAuth")) {
        if (request.headers.has('customHeaders')) {
          return this.customHeader(request, next);
        }
        return this.cloneHeader(request, next, hasAccountId);
      } else {
        request = request.clone({
          headers: request.headers.delete("noAuth"),
        });
        return next.handle(request);
      }
    } else {
      this.authService.refreshToken(() => {
        if (!request.headers.has("noAuth")) {
          if (request.headers.has('customHeaders')) {
            return this.customHeader(request, next);
          }
          this.cloneHeader(request, next, hasAccountId);
        } else {
          request = request.clone({
            headers: request.headers.delete("noAuth"),
          });
        }
        // send cloned request with header to the next handler.
        return next.handle(request);
      });
    }
  }

  private cloneHeader(request, next, hasccountId) {
    let correlationid = this.splunkService.corelationID;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.getRawIdToken()}`,
      correlationid: correlationid.toString(),
    });
    request = request.clone({ headers });
    if (!hasccountId) {
      request = request.clone({
        headers: request.headers.set(
          "accountid",
          sessionStorage.getItem("accountId")
        ),
      });
    }
    return next.handle(request); //Unique id for the session
  }

  private customHeader(request, next) {
    let correlationid = this.splunkService.corelationID;
    
    request = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${this.authService.getRawIdToken()}`)
        .set('correlationid', correlationid.toString())
    });
    return next.handle(request);
  }
}
