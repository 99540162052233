import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "dateFormat"
})
export class DateFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(value) {
    if (value) {
      return this.datePipe.transform(new Date(value), "M/d/yyyy h:mm:ss a");
    }
  }
}
