import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { SessionTimeoutConfirmationDialogComponent } from "../session-timeout/session-timeout-confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { AuthService } from "./authentication.service";

@Injectable()
export class UserActivityService {
  idleState = "Not started.";
  timedOut = false;
  constructor(
    public dialog: MatDialog,
    private idle: Idle,
    private authService: AuthService
  ) {}

  public userActivity() {
    // debugger;
    this.idle.setIdle(2);
    this.idle.setTimeout(environment.ageroAuthConfig.activityTimeout);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      this.authService.activityTimer();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out!";
      this.timedOut = true;
      console.log(this.idleState);
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      //console.log(this.idleState)
    });

    this.idle.onTimeoutWarning.subscribe(countdown => {
      // this.idleState = 'You will time out in ' + countdown + ' seconds!';
      // let authtime= Math.round(countdown/60)
      if (countdown == environment.popupTimer) {
        this.showSessionTimeoutConfirmationPopup();
      }
      // console.log(this.idleState)
    });

    this.reset();
  }
  private reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }
  private showSessionTimeoutConfirmationPopup(): void {
    const dialogRef = this.dialog.open(
      SessionTimeoutConfirmationDialogComponent,
      {
        panelClass: "screen-dialog",
        autoFocus: false
      }
    );
  }
}
