import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthService } from "../_service/authentication.service";
import { environment } from "../../../../environments/environment";

// import { AuthService } from "../_services/authentication.service";

@Injectable()
export class IdentityGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate() {
    if (environment.withSSO) {
      return this.authService.checkLogin();
    } else {
      return true;
    }
  }
}