import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ACTIVITY_CONSTANT, CASE_STATUS, LOCALSTORAGE_KEYS, REQUEST_ENDPOINTS, SESSIONSTORAGE_KEYS } from '../_models';
import { SplunkService } from '../../_service/splunk.service';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AMActivityService {
  public activityCaseInformationSource = new BehaviorSubject<any>(null);

  public retryAttempts = 0;
  public intervalSubscription = new Subscription();
  public activityIntervalSubscription = new Subscription();
  private snackBarRef: MatSnackBarRef<TextOnlySnackBar> | undefined;

  constructor(private http: HttpClient, private splunkService: SplunkService, private matSnack: MatSnackBar, private router: Router) { }


  /**
   * method to get next activity assigned to loggedin user
   * @returns next activity 
   */
  getNextActivity(): Observable<any> {
    return this.http.get<Observable<any>>(environment.API.amActivityAPI + REQUEST_ENDPOINTS.AM_NEXT_ACTIVITY);
  }



  /**
   * method to update activity
   * @param state success/unsuccess
   * @param data  payload
   * @returns activity 
   */
  updateActivity(data: any): Observable<any> {
    const caseInformation = this.activityCaseInformationSource.value;
    const content = Object.assign({ "data": data }, caseInformation.data.template?.output);
    const request = {
      "activityId": caseInformation.data.activityId,
      "caseId": caseInformation.data.caseId,
      "data": content
    }
    return this.http.post(environment.API.amActivityAPI + REQUEST_ENDPOINTS.AM_UPDATE_ACTIVITY, request);
  }


  /**
   * method to get case activities
   * @returns case activities 
   */
  getCaseActivities(): Observable<any> {
    const caseId = this.activityCaseInformationSource.value?.data?.caseId;
    const programCode = localStorage.getItem(LOCALSTORAGE_KEYS.PROGRAM_CODE);
    
    return this.http.get(environment.API.URL + `/api/clients/${programCode}/activities/${caseId}`);
  }



  /**
   * method to set timer if case is not available
   */
  public setTimerIfCaseNotAvailable(): void {
    this.intervalSubscription?.unsubscribe();
    const timer = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.AM_ACTIVITY_TIMER)!) ?? 5;
    const authToken = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.AUTH_TOKEN)!);

    this.intervalSubscription = interval(timer * 60 * 1000).subscribe(() => {
      this.retryAttempts++;

      this.activityIntervalSubscription.unsubscribe();
      this.activityIntervalSubscription = this.getNextActivity().subscribe(
        (response: any) => {
          if (!response.data?.caseData) {
            const description = `AM Activity: Activity could not be found.`;
            const message = `Activity could not be found for user: ${authToken?.userName}, Retry attempt: ${this.retryAttempts}`;
            this.addSplunkMessage(description, message);
          }
          else {
            this.intervalSubscription.unsubscribe();
            this.onActivityFound(response);

            this.snackBarRef = this.matSnack.open("A new activity got assigned to you.", `Open`);
            this.snackBarRef.onAction().subscribe(() => {
              this.router.navigateByUrl("/dashboard/activity/am/main");
              this.snackBarRef?.dismiss();
            });
          }
        },
        (error: any) => {
          this.clearCaseInformation();
          const description = `AM Activity: Activity could not be retrieved.`;
          const message = `Activity could not be retrieved for user: ${authToken?.userName}`;
          const extralog = `${JSON.stringify(error)}`;
          this.addSplunkErrorMessage(description, message, extralog);
        });
    });
  }


  /**
   * method to add logs for login
   * @returns login 
   */
  onLogin(): Observable<any> {
    return this.http.post(environment.API.amActivityAPI + REQUEST_ENDPOINTS.AM_LOGIN, {});
  }



  /**
   * method to add logs for logout
   * @returns log out 
   */
  onLogoutXHR(): any {
    const userName = localStorage.getItem(LOCALSTORAGE_KEYS.AM_ACTIVITY_LOGGED_IN_USER);
    if (!userName) {
      return null;
    }

    let xhr = new XMLHttpRequest();
    xhr.open("POST", environment.API.amActivityAPI + REQUEST_ENDPOINTS.AM_LOGOUT, true);
    xhr.setRequestHeader("am-agent-id", userName);
    xhr.setRequestHeader("Authorization", `Basic ${environment.amLogoutAPIKey}`);
    xhr.send();

    this.clearCaseInformation();
    this.intervalSubscription.unsubscribe();
    sessionStorage.removeItem(SESSIONSTORAGE_KEYS.AM_ACTIVITY_USER_READY);

    let logData = {
      logLevel: "Info",
      extraLog: `false`,
      logDescription: `AM Activity: User is getting logged out`,
      logMessage: `User: ${userName} is getting logged out`,
    };
    this.splunkService.splunkLog(logData);
  }



  /**
   * method to convert input time to EST timezome
   * @param date 
   * @returns to est 
   */
  private convertToEST(date: string): any {
    if (date)
      return new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York' });
    return "";
  }



  /**
   * method to map Case details in Detail
   * @param caseData 
   * @returns  
   */
  caseDetailMapper(caseData: any): any {
    const emptyObject = {};
    const inputData = caseData.data ?? emptyObject;
    const claim = inputData.claim ??emptyObject;
    const customer = inputData.customer?? emptyObject;
    const locationInformation = inputData.locationInformation ?? emptyObject;
    const caller = inputData.caller ?? emptyObject;
    const vehicleInformation = inputData.vehicleInformation ?? emptyObject;
    const damageInformation = inputData.damageInformation ?? emptyObject;
    const ageroStorageLocation = inputData.ageroStorageLocation ?? emptyObject;
    const slmAddtlEquipmentCharge = inputData.slmAddtlEquipmentCharge ?? emptyObject;
    const chargeInformation = inputData.chargeInformation ?? emptyObject;

    return {
      "caseOverview": {
        "internalId": caseData.internalCaseId,
        "claim": claim?.claimNumber,
        "lossReportNumber": claim?.lossReportNumber,
        "personInPossessionOfVehicle": customer.personInPossession,
        "accountName": inputData.clientName,
        "subsidiaryLibertyName": inputData.subId,
        "subsidiaryName": inputData.subId,
        "policyNumber": claim?.policyNumber,
        "policyStateName": claim?.policyState,
        "stockLotNumber": locationInformation?.towDestination?.stockLot,
        "poNumber": inputData.dispatchInformation?.poNumber,
        "product": claim?.assignmentType,
        "initialProduct": inputData.initialProduct,
        "slmClassification": inputData.slmClassification,
        "customerPaysAllCharges": claim?.customerPay,
        "coverageType": claim?.customerCoverageType,
        "customerPaysDollarAmount": claim?.customerCoverageValue ? claim?.customerCoverageValue : "0.00",
        "customerPaysMileageAmount": claim?.customerCoverageValue ? claim?.customerCoverageValue : "0",
        "clientObtainedApprovalToText": customer?.textAllowed,
        "alternateTransport": customer?.requestAlternateTransportation,
        "isReleaseRequired": locationInformation.pickupLocation.isReleaseRequired,
        "availableForPickup": inputData.vehicleAvailable,
        "type": claim?.typeOfLoss,
        "dateOfLoss": claim?.dateOfLoss,
        "caseNumber": caseData.caseNumber,
        "externalCaseId": caseData.id,
        "status": CASE_STATUS[caseData.caseStateCode] ?? caseData.caseState,
        "dispatchSystem": inputData.dispatchInformation?.dispatchSystem,
        "customerFirstName": customer?.firstName,
        "customerLastName": customer?.lastName,
        "customerPhone": customer.mobilePhoneNumber ? customer.mobilePhoneNumber : customer.phoneNumber1,
        "customerEmail": customer.email,
        "caseOrigin": caseData.origin,
        "createdDate": this.convertToEST(caseData.createDateTime),
        "closedDate": this.convertToEST(inputData?.closedDate),
        "cancelledDate": this.convertToEST(inputData.cancelledDate),
        "keyAvailable": inputData.keysAvailable == "Yes",
        "keyLocationText": inputData.keysLocation,
        "claimTypeName": claim?.claimType
      },
      "assignerInfo": {
        "name": `${caller.firstName} ${caller.lastName}`,
        "phone": caller.phoneNumber,
        "extension": caller.extension,
        "email": caller.email,
        "userName": caller.employeeId,
      },
      "vehicleInfo": {
        "vin": vehicleInformation.vin,
        "year": vehicleInformation.year,
        "make": vehicleInformation.make,
        "model": vehicleInformation.model,
        "class": vehicleInformation.class,
        "licensePlate": vehicleInformation.licensePlate,
        "licensePlateState": vehicleInformation.plateState,
        "color": vehicleInformation.color,
        "driveTrain": vehicleInformation.driveTrain
      },
      "damageInfo": {
        "damageFrontEnd": damageInformation.frontEnd,
        "damageRearEnd": damageInformation.rearEnd,
        "damageLeftSide": damageInformation.driverSide,
        "damageRightSide": damageInformation.passengerSide,
        "damageVehicleLeakingFluids": damageInformation.leakingFluids,
        "damageFlatTires": damageInformation.flatTires,
        "damageNoTires": damageInformation.noTires,
        "damageWillWheelsRoll": damageInformation.willWheelsRoll,
        "damageNoKeys": damageInformation.noKeys,
        "damageSteeringColumn": damageInformation.steeringColumn,
        "damageUnderCarriage": damageInformation.underCarriage,
        "damageUnknown": damageInformation.unknown,
        "damageFlood": damageInformation.flood,
        "damageFlipped": damageInformation.flipped,
        "damageOther": damageInformation.other,
        "damageOtherDescription": damageInformation.otherDescription,
        "airBagsDeployed": damageInformation.airBagDeployed
      },
      "pickUpLocation": {
        "locationName": locationInformation.pickupLocation?.name,
        "address": locationInformation.pickupLocation?.address,
        "city": locationInformation.pickupLocation?.city,
        "state": locationInformation.pickupLocation?.state,
        "zipCode": locationInformation.pickupLocation?.zip,
        "phone": locationInformation.pickupLocation?.phoneNumber,
        "email": locationInformation.pickupLocation?.email,
        "locationType": locationInformation.pickupLocation?.category
      },
      "destinationLocation": {
        "locationName": locationInformation.towDestination?.name,
        "address": locationInformation.towDestination?.address,
        "city": locationInformation.towDestination?.city,
        "state": locationInformation.towDestination?.state,
        "zipCode": locationInformation.towDestination?.zip,
        "phone": locationInformation.towDestination?.phoneNumber,
        "email": locationInformation.towDestination?.email,
        "miles": inputData.dispatchInformation?.towDistance ? parseInt(inputData.dispatchInformation?.towDistance): ""
      },
      "ageroStorageLocation": {
        "ageroStorageName": ageroStorageLocation.name,
        "ageroStorageStreetAddress": ageroStorageLocation.address,
        "ageroStorageCity": ageroStorageLocation.city,
        "ageroStorageState": ageroStorageLocation.state,
        "ageroStorageZipCode": ageroStorageLocation.zip,
        "ageroStoragePhone": ageroStorageLocation.phone,
        "dateEnteredStorage": this.convertToEST(ageroStorageLocation.dateEntered),
        "milesToAgeroStorage": inputData.milesFrmPickuptoAgeroStorage,
        "dateMovedFromStorage": this.convertToEST(inputData.dateMovedFromAgeroStorage),
        "milesFrmStorageToDest": inputData.milesFrmAgeroStoragetoDestination,
        "doesAgeroStoreVehicle": inputData.storeVehicle
      },
      "releaseRequirements": {
        "requirements": inputData.releaseRequirement?.releaseRequirement,
        "paymentTypes": inputData.releaseRequirement?.acceptedPaymentTypes,
        "dateOfImpound": inputData.releaseRequirement?.dateOfImpound,
        "impoundReason": inputData.releaseRequirement?.impoundReason
      },
      "advanceCharges": {
        "totalCharges": chargeInformation.total,
        "primaryTow": chargeInformation.primaryTow,
        "labor": chargeInformation.labor,
        "cleanUp": chargeInformation.cleanUp,
        "subletCharge": chargeInformation.sublet,
        "specialEquipment": chargeInformation.winching,
        "gateFee": chargeInformation.gate,
        "adminFee": chargeInformation.admin,
        "daysOfStorage": chargeInformation.daysOfStorage,
        "storageCostPerDay": chargeInformation.storageCostPerDay,
        "storageTotal": chargeInformation.storageTotalCost,
        "preservationFee": chargeInformation.preservation,
        "impoundFee": chargeInformation.impound,
        "notificationFee": chargeInformation.notification,
        "tax": chargeInformation.tax,
        "tearDownCharges": chargeInformation.teardown,
        "other": chargeInformation.other,
        "otherDesc": chargeInformation.otherDescription,
        "serviceCharge": null,    // check
        "totalAdvanceCharges": chargeInformation.totalAdvanceCharges,
        "convenienceFeeDollar": chargeInformation.convenience,
        "convenienceFeePercent": chargeInformation.conveniencePercentage,
        "additionalAddPay": chargeInformation.additionalAddPay,
        "goodThroughDate": this.convertToEST(chargeInformation.goodThroughDate)
      },
      "caseCancellation": {
        "reason": inputData.cancelledReason,
        "requestedBy": inputData.cancelledRequestedBy
      },
      "delayedStatusInfo": {
        "delayReason": "",    // check
        "delayIndicator": inputData.caseDelayed?.delayedResponse,
        "isCaseEscalated": inputData.isCaseEscalated
      },
      "slmCharges": {
        "slmEnRouteMiles": slmAddtlEquipmentCharge.enrouteMiles,
        "slmLoadedMiles": slmAddtlEquipmentCharge.loadedMiles,
        "slmTowOutMiles": slmAddtlEquipmentCharge.towoutMiles,
        "slmConvenienceFeePercentage": slmAddtlEquipmentCharge.convenienceFeePercentage,
        "slmEnRouteCostpermile": slmAddtlEquipmentCharge.enrouteCostPerMile,
        "slmTotalEnRouteCost": slmAddtlEquipmentCharge.totalEnrouteCost,
        "slmTowcostHookupcharge": slmAddtlEquipmentCharge.towcostHookupCharge,
        "slmLoadedMilesCostpermile": slmAddtlEquipmentCharge.loadedMilesCostPerMile,
        "slmTotalLoadedMilesCost": slmAddtlEquipmentCharge.totalLoadedMilesCost,
        "slmLaborCostperminute": slmAddtlEquipmentCharge.laborCostPerMinute,
        "slmTotalLaborCost": slmAddtlEquipmentCharge.totalLaborCost,
        "slmWinchingCostperminute": slmAddtlEquipmentCharge.winchingCostPerMinute,
        "slmTotalWinchingCost": slmAddtlEquipmentCharge.totalWinchingCost,
        "slmAdditionalEquipment": slmAddtlEquipmentCharge.addtlEquipment,
        "slmStorageCostperday": slmAddtlEquipmentCharge.storageCostPerDay,
        "slmTotalStorageCost": slmAddtlEquipmentCharge.totalStorageCost,
        "slmTowOutcostpermile": slmAddtlEquipmentCharge.towoutCostPerMile,
        "slmTotalTowOutMilesCost": slmAddtlEquipmentCharge.totalTowoutMilescost,
        "slmTowOutCosthookupcharge": slmAddtlEquipmentCharge.towoutCostHookupCharge,
        "slmTaxCost": slmAddtlEquipmentCharge.tax,
        "slmConvenienceFeeCost": slmAddtlEquipmentCharge.convenienceFee,
        "slmTotalSLMCharges": slmAddtlEquipmentCharge.totalCharge,
        "laborMinutes": slmAddtlEquipmentCharge.laborMinutes,
        "winchingMinutes": slmAddtlEquipmentCharge.winchingMinutes,
        "storageDays": slmAddtlEquipmentCharge.storageDays,
        "additionalEquipmentDescription": slmAddtlEquipmentCharge.addtlEquipmentDesc
      },
      "internalAttributes": {
        "originVersion": caseData.originVersion
      }
    }
  }



  /**
   * method to update rxjs subject when activity is available
   * @param data 
   */
  onActivityFound(data: any): void {
    sessionStorage.setItem(SESSIONSTORAGE_KEYS.AM_ACTIVITY_OPEN, 'true');
    this.activityCaseInformationSource.next(data);
  }



  /**
   * method to clear rxjs subject for Activity
   */
  clearCaseInformation(): void {
    this.activityCaseInformationSource.next(null);
    sessionStorage.removeItem(SESSIONSTORAGE_KEYS.AM_ACTIVITY_OPEN);
  }


  /**
   * method to clear notification subscription
   */
  clearNotification(): void {
    this.snackBarRef?.dismiss();
  }


  /**
   * captures log details in the splunk
   */
  public addSplunkMessage(description: string, message: string): void {
    let logData = {
      logLevel: ACTIVITY_CONSTANT.LOG_LEVEL_INFO,
      extraLog: "false",
      logDescription: description,
      logMessage: message,
    };
    this.splunkService.splunkLog(logData);
  }


  /**
   * capture error details in the splunk
   */
  public addSplunkErrorMessage(description: string, message: string, extralog: string = "false"): void {
    let logData = {
      logLevel: ACTIVITY_CONSTANT.LOG_LEVEL_ERROR,
      extraLog: extralog,
      logDescription: description,
      logMessage: message,
    };
    this.splunkService.splunkLog(logData);
  }
}
