import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, retryWhen } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { SplunkService } from '../../_service/splunk.service';
import { ACTIVITY_CONSTANT, CaseAssignRequest, Extension, ICaseFilterResult, IResponse, PDialRequest, PhoneStatus, REQUEST_ENDPOINTS } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  constructor(
    private http: HttpClient,
    private splunkService: SplunkService
  ) { }


  /**
   * method to allow user to login into the CTI with extension number
   */
  public loginUser(extension: Extension): Observable<IResponse> {
    return this.http.post<IResponse>(environment.API.acnActivityAPI + REQUEST_ENDPOINTS.LOGIN, extension);
  }


  /**
   * method to update telephony phone status in CTI
   */
  public updatePhoneStatus(phoneStatusRequest: PhoneStatus): Observable<any> {
    return this.http.post(environment.API.acnActivityAPI + REQUEST_ENDPOINTS.UPDATE_PHONE_STATUS, phoneStatusRequest);
  }


  /**
   * method to connect with Avaya telephony service
   */
  public connectViaAvaya(pdialRequest: PDialRequest): Observable<any> {
    return this.http.post(environment.API.acnActivityAPI + REQUEST_ENDPOINTS.PDIAL, pdialRequest).pipe(
      retryWhen(error =>
        error.pipe(
          concatMap((error, count) => {
            if (count < ACTIVITY_CONSTANT.RETRY_PDIAL_ATTEMPTS && ACTIVITY_CONSTANT.RETRY_PDIAL_STATUS_CODES.includes(error.status)) {
              this.addSplunkErrorMessage("Retrying pDial calling", `PDial retry calling count: ${count}`);
              return of(error);
            }
            return throwError(error);
          })
        )
      ));
  }


  /**
   * method to get ACN activity cases
   */
  public getACNCases(filterset: any): Observable<ICaseFilterResult> {
    const httpOptions = {
      headers: new HttpHeaders({
        'customHeaders': 'true',
        'x-am-request-type': 'acn'
      }),
    };
    return this.http.post<ICaseFilterResult>(environment.API.acnSearchAPI + REQUEST_ENDPOINTS.CASE_SEARCH, filterset, httpOptions);
  }


  /**
   * method to get template based activity detail 
   */
  public getActivityDetail(caseId: string, agentName: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'customHeaders': 'true',
        'x-am-internalCaseType': 'acn',
        'x-am-dataSource': 'internal',
        'x-am-agent': agentName
      })
    };
    return this.http.get(environment.API.acnSearchAPI + REQUEST_ENDPOINTS.ACTIVITY_DETAIL + `${caseId}`, httpOptions);
  }


  /**
   * method to save an activity
   */
  public onSaveActivity(caseId: string, e911UniqueCaseId:string, data: any): Observable<any> {
    const request = {
      "eventId": this.create_GUID(),
      "event": "acn.case.update",
      "eventOrigin": "amd",
      "createdOn": new Date().toISOString(),
      "content": {
        "caseId": caseId,
        "e911UniqueCaseId":e911UniqueCaseId,
        "caseUpdateDate": new Date().toISOString(),
        "data": data
      }
    }
    return this.http.post(environment.API.acnSearchAPI + REQUEST_ENDPOINTS.UPDATE_ACTIVITY, request);
  }


  /**
   * updates case assignee to the Activity
   */
  public updateCaseAssignee(caseAssignRequest: CaseAssignRequest): Observable<any> {
    const request = {
      "eventId": this.create_GUID(),
      "event": "acn.case.owner.assigned",
      "eventOrigin": "amd",
      "content": {
        "caseId": caseAssignRequest.caseId,
        "ownerName": caseAssignRequest.assignedTo
      }
    }
    return this.http.post(environment.API.acnSearchAPI + REQUEST_ENDPOINTS.UPDATE_ACTIVITY, request);
  }


  /**
   * generates random GUID
   */
  private create_GUID(): string {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }


  /**
   * captures log details in the splunk
   */
  public addSplunkMessage(description: string, message: string): void {
    let logData = {
      logLevel: ACTIVITY_CONSTANT.LOG_LEVEL_INFO,
      extraLog: "false",
      logDescription: description,
      logMessage: message,
    };
    this.splunkService.splunkLog(logData);
  }


  /**
   * capture error details in the splunk
   */
  public addSplunkErrorMessage(description: string, message: string, extralog: string = "false"): void {
    let logData = {
      logLevel: ACTIVITY_CONSTANT.LOG_LEVEL_ERROR,
      extraLog: extralog,
      logDescription: description,
      logMessage: message,
    };
    this.splunkService.splunkLog(logData);
  }

  


  /**
   * method to get template based activity detail 
   */
  public createTowRequest(caseId: string, data: any): Observable<any> {
    const request = {
      "eventId": this.create_GUID(),
      "event": "acn.asm.create",
      "eventOrigin": "amd",
      "createdOn": new Date().toISOString(),
      "content": {
        "caseId": caseId,
        "e911UniqueCaseId":null,
        "caseUpdateDate": new Date().toISOString(),
        "data": data
      }
    }
    return this.http.post(environment.API.acnSearchAPI + REQUEST_ENDPOINTS.UPDATE_ACTIVITY, request);
  }
}


