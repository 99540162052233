export interface Extension {
    phoneExtension: string,
    emailAddress: string,
    userName: string
}

export interface PhoneStatus {
    phoneExtension: string,
    emailAddress: string,
    phoneStatus: string
}

export interface IResponse {
    isSuccess: boolean,
    responseCode: string,
    responseMessage: string
}

export interface CaseAssignRequest {
    caseId: string,
    assignedTo: string
}

export interface PDialRequest {
    agentExtension: string,
    numberToDial: string,
    agentEmail: string,
    destinationType: string
}


export interface ICaseFilterResult {
    total: number,
    data: ICaseDetail[]
}

export interface ICaseDetail {
    agentAssigned: string,
    caseId: string,
    caseState: string,
    caseStatus: string,
    clientAccount: IClientAccount,
    clientCode: string,
    crmCaseNumber: string,
    customer: ICustomer,
    deEscalated: string,
    emsContacted: string,
    emsRequested: string,
    internalCaseId: string,
    pickupLocation: ILocationCoordinate,
    softTriageStatus?: boolean,
    towRequested: string,
    typeEmsRequested: string
}

export interface ICustomer {
    alternatePhoneNumber1: string,
    alternatePhoneNumber2: string,
    clientObtainedApprovalToText: string,
    email: string
    firstName: string,
    lastName: string,
    mobilePhoneNumber: string,
    personInPossessionOfVehicle: string,
    pickupDateTimeEnd: string,
    pickupDateTimeStart: string,
    requestAlternateTransportation: string,
    withVehicle: string,
}

export interface IClientAccount {
    appName: string,
    clientAccount: string,
    clientType: string,
    organization: string
}

export interface ILocationCoordinate {
    Name: string,
    address: string,
    city: string,
    comment: string,
    country: string,
    crossStreet: string,
    emailAddress: string,
    facilityType: string,
    latitude: string,
    longitude: string
    phoneNumber: string,
    state: string,
    zip: string,
}

export interface IQuestionCondition {
    parentControl: string,
    condition: string,
    value: string,
    validators: {
        required: boolean,
        isEnabled: boolean,
        pattern: string
    }
}

export interface IQuestion {
    key: string,
    value: string,
    label: string,
    type: string,
    parentControls?: string[],
    conditions?: IQuestionCondition[],
    options: [{
        key: string,
        value: string
    }],
    validators: {
        isRequired: boolean,
        pattern: string,
        isEnabled: boolean
    },
    isSummaryRequired: boolean,
    isHidden: boolean,
    order?: number
}

export interface IActivity {
    caseNumber: string,
    status: string,
    activityName: string,
    activity: IQuestion[],
    caseDetail: any[]
}

export interface WSResponse {
    eventType: string,
    isDataPop: boolean,
    userName: string,
    message: string,
    data: WSDataPopMessage
}

export interface WSDataPopMessage {
    caseId: string,
    callingNumber: string
}

