export const REQUEST_ENDPOINTS = {
  LOGIN: '/login',
  PDIAL: '/placeOutboundCall',
  UPDATE_PHONE_STATUS: '/phoneStatusUpdate',
  CASE_SEARCH: '/search/case',
  QUESTIONNAIRE: '/activityDetail',
  UDPATE_CASE_ASSIGNEE: '/requestAssignment',
  ACTIVITY_DETAIL: '/dynamicui/config/',
  UPDATE_ACTIVITY: '/case/event',
  AM_NEXT_ACTIVITY: '/assignment',
  AM_UPDATE_ACTIVITY: '/assignment',
  AM_LOGOUT: '/user/logout',
  AM_LOGIN: '/user/login',
  CAN_ACTIVITIES: '/can/'
}

export const ACTIVITY_CONSTANT = {
  MOCK_EXTENSION_NUMBER: "99999",
  EXTENSION_PATTERN: "[\\d]{5}",
  PAGE_SIZE: 15,
  LOG_LEVEL_INFO: "Info",
  LOG_LEVEL_ERROR: "Error",
  SNACK_BAR_TIMER: 5000,
  SNACK_BAR_SHORT_TIMER: 2000,
  RETRY_PDIAL_ATTEMPTS: 2,
  RETRY_PDIAL_STATUS_CODES: [0, 500, 501, 503],
  SUPPORTED_ACTIVITIES: ['ACN'],
  EDITABLE_STATUSES: ['in_progress', 'new', 'In Progress', 'New', 'In Progress - Agent Assigned', 'InProgressAgentAssigned'],
  FALSE_ACN_CONDITIONS: ['No', 'De-escalate'],
  NO_CASE_ASSIGNED_STATUSES: ['ACNComplete'],
  YES: 'Yes',
  NO: 'No',
  DEESCALATE: "De-escalate",
  DEESCALATE_WS_EVENT: "acn.deescalate.request",
  COLOR_STATUS_NEW: "#3DCC79",
  COLOR_STATUS_IN_PROGRESS: "#FF9A4D",
  COLOR_STATUS_FALSE_ACN: "#FF695B",
  COLOR_STATUS_ACN_COMPLETE: "#36BDE8",
  COLOR_CURRENT_UPDATED_CONTROL_LABEL: "#006CE6",
  COLOR_DEFAULT_LABEL: 'black',
  COLOR_STATUS_OFFLINE: '#8D8D8D',
  OUTSIDE_US_AND_CANADA: 'Outside US and Canada',
  ACTIVITY_ROUTE_PATH: '/dashboard/activity/acn',
  ACTIVITY_MAIN_ROUTE_PATH: '/dashboard/activity/acn/main-activity',
  ACTIVITY_HOME_ROUTE_PATH: ['/dashboard/activity/acn', '/dashboard/activity/acn/activity-dashboard']
}

export const LOCALSTORAGE_KEYS = {
  EXTENSION_NUMBER: 'extensionNumber',
  EMAIL_ADDRESS: 'userEmailAddress',
  READONLY_USER: 'activityReadOnlyUser',
  PHONE_STATUS: 'phoneStatus',
  CASE_DETAIL: 'actCaseDetail',
  AUTH_TOKEN: 'ageroAuth_decodedIdToken',
  CURRENT_UPDATED_CONTROL: 'crntUpdatedKey',
  CASE_ASSIGNED_VIA_WS: 'isCaseAssignedViaWS',
  PROGRAM_CODE: 'currentProgramCode',
  AM_ACTIVITY_TIMER: 'amActivityRecheckTimer',
  AM_ACTIVITY_LOGGED_IN_USER: 'amActivityLoggedInUser'
}

export const SESSIONSTORAGE_KEYS = {
  LONGITUDE: 'longitude',
  LATITUDE: 'latitude',
  E911UNIQUECASEID: 'e911UniqueCaseId',
  AM_ACTIVITY_USER_READY: 'amActivityUserReady',
  AM_ACTIVITY_OPEN: 'amActivityOpen',
  CASE_NUMBER: 'activityCaseNumber',
}

export enum ACNCaseStatus {
  'new' = 'New',
  'in_progress' = 'In Progress',
  'FalseACN' = 'False ACN',
  'ACNComplete' = 'ACN Complete',
  'InProgressAgentAssigned' = 'In Progress - Agent Assigned'
}

export enum ACNMappedCaseStatus {
  'New' = 'new',
  'In Progress' = 'in_progress',
  'False ACN' = 'FalseACN',
  'ACN Complete' = 'ACNComplete',
  'In Progress - Agent Assigned' = 'InProgressAgentAssigned'
}

export const RIV_CONSTANT = {
  YES: 'Yes, in accident',
  NO: 'No, not in accident',
}

export const CASE_STATUS = {
  "2": "On Hold",
  "100000002": "Cancel Case - GOA",
  "5": "Problem Solved",
  "6": "Canceled",
  "200000": "New",
  "200003": "IP - Dispatched",
  "200004": "Closed",
  "200005": "IP - Customer Contacted",
  "200006": "IP - Pickup Location Contacted",
  "200007": "IP - Ready To Dispatch",
  "200009": "IP - Vehicle ready to be released",
  "200011": "Redispatch - GOA",
  "200012": "Redispatch - Vendor Cancel",
  "200013": "Redispatch - Customer Cancel",
  "200014": "Closed - Paid Advance Charges Only",
  "200016": "Redispatch - Same Provider",
  "200017": "Redispatch - New Service Provider",
  "200018": "Redispatch - Tow to Storage",
  "200019": "Per Event",
  "100000000": "IP - Get Tow Out PO",
  "100000001": "IP - Scheduled Dispatch"
}
