export const environment = {
  production: false,
  envName: "Dev",
  version: "1.0",
  withSSO: true,
  logging: true,
  hashPIIData: true,
  logToAnalytics: {
    SendEvent: true,
    SendRoute: true,
    SendException: true,
  },
  extraLoggs: true, //it will be false after successfull production.
  popupTimer: 300, //5 minute before
  dupCheckTimer: 15000, // after 15 sec
  API: {
    URL: "https://amdevdashboard-api.agero.com",
    vinDecoder: "https://api.enterprise.agero.com/vindecoderapi/v1/VinDecoder",
    vehicleApi: "https://agero-stg.apigee.net/vehicleinfo/api/v1/Vehicle",
    splunk: "https://agero-stg.apigee.net/amdashboard/services/collector/event",
    caseCreateSchema: "/assets/documents/client+account+rules_schema_dev.json",
    numberValidate:
      "https://vx6nvas6v1.execute-api.us-east-1.amazonaws.com/dev/validate",
    addressValidation:
      "https://dtugzz02v3.execute-api.us-east-1.amazonaws.com/nonprod",
    towbookApi: "https://ldtk6qkffc.execute-api.us-east-1.amazonaws.com/qa",
    towbookMultidownloadApi:
      "https://il7zbddrtk.execute-api.us-east-1.amazonaws.com/nonprod",
    acnActivityAPI: 'https://agero-dev.apigee.net/am-cti-integration-api',
    acnSearchAPI: 'https://agero-dev.apigee.net/am-gateway-manager-api-token',
    websocket_URL: 'wss://am-websocket-dev.am-gateway.com/',
    e911Activity_URL:'https://qa.ageroe911.com/',
    amActivityAPI: 'https://agero-dev.apigee.net/am-abm',
    chatbotAPI: 'agero-lb-staging.dev',
  },

  mapKey: "AIzaSyD0Y9uo49S7Pyt-M2I3A5E3K1AuyzE7fHs",
  // vinAuth: "ElZ7VnIGxzIBh95G4tq46y5y952ElTtW",
  vinAuth: "hvjIwj76TFODTHAxl3G2A35yGXGql7At",
  vehicleAuth: "Hbae19ZAfZUfZ2WcnmNjlCo25ViFn8VA",
  splunkAuth: "Xd96nu1FCNs1SJRexgNWKR5ZJJf06cVo",
  numberValidationKey: "0TRTCH4Wvg8xUCNGm3jR39XqONUEbLk06fvpm5w7",
  addressValidationKey: "WQz19J1vco6G7EFzKj1CF2fLMOL1Mn7UawJG9Zqd",
  towbookApiKey: "in38zQwCfa7tMsIVdtVgU1nawqZTbMWs9v9ISi0f",
  towbookMultidownloadApiKey: "nj7WY5u5xX3n8BMgWrY3aNtaMoaqDFW3OI9DnDo6",
  amLogoutAPIKey: "aXhsQU5hWFdZbUc0aGRNWVpYWUNvaHF1SHBMdjY2eDU6czU1a2pUVWlxalI1eXRHdw==",
  chatbotKey: "1ef55030d43e0216ba0e0242ac130014",
  locateMeConfig: {
    enable: true,
    sendMessageURL: "https://agero-stg.apigee.net/twowaycommapi/v2/Messages",
    sendAuth: "jS6a1jBzESDycg1DR9XIX8VZHOf4V6pf",
    getUserLocationURL:
      "https://agero-stg.apigee.net/locatemeapi/api/getlocation",
    payloadConfig: {
      is2way: false,
      timeToLiveInMinutes: 2,
      fromNumber: "+16178638775",
      body: "Please click the link below to report your current location to your Accident Management Agent https://stage.locationconfirm.com/?num",
      outboundStatusCallback:
        "https://stage.locationconfirm.com/api/addsmscallback",
    },
  },
  clientCode: {
    Agero: "AGR",
  },
  adminGroup: "Agero_VIMS_AgeroAdmin",
  readOnlyGroup: "Agero_VIMS_AgeroReadonly",
  activityGroup: "Agero_VIMS_AgeroAgent",
  amdActivityGroup: "Agero_VIMS_ActivityWorker",
  google: {
    GTagTrackingID: "G-EP408XDF7W",
  },
  ageroAuthConfig: {
    clientId: "45082ggdn4pim21h5v9q9l4dff",
    appWebDomain: "login.nonprod.oauth.agero.com", //d2im3hcmas9r4q.cloudfront.net
    redirectUriSignIn: "https://amdevdashboard.agero.com/callback.html",
    redirectUriSignOut: "https://amdevdashboard.agero.com/logout.html",
    apiFullUrl: "https://nonprod.api.oauth.agero.com/multi-nonprod/api/v1",
    useMultiTenant: true,
    refreshTimeoutOffset: 10 * 60,
    // How many seconds of inactivity before the user is signed out.  If not included, defaults to 30 minutes
    activityTimeout: 30 * 60,
  },
  exportAMUsersConfig: {
    auth0Clients: [
      { name: "Hagerty", id: "con_ljs1qB5T7HG1n35R" },
      { name: "Cincinnati Insurance", id: "con_OOx14sun1dEnLNT3" },
      { name: "Montway Auto Transport", id: "con_b09xdiWsKMlAYDxp" },
      { name: "Wawanesa", id: "con_v1IpVY5JY40XrMpD" },
      { name: "Sentry", id: "con_Tsw4OdcV3RTKcL7A" },
      { name: "Country Financial", id: "con_ZqvVpVvWwiMG99ar" },
      { name: "Metromile", id: "con_LL0FLLtIViEbORme" },
      { name: "Mercury", id: "con_VecdT78wvv3WSsBr" },
      { name: "Infinity", id: "con_yv18ymi2ZdgsqzeS" },
      { name: "Shelter", id: "con_Q9oA6C5J5cnIexzl" },
      { name: "American Family", id: "con_NLalvIGvAlRZXxrZ" },
      { name: "ACD AM", id: "con_s2WO95251nAxW4r3" },
      { name: "IAA AM", id: "con_9npRALIwCfJ1yd7y" },
      { name: "Enumclaw AM", id: "con_n0lquTfS3ETAzEYX" },
    ],
  },
  BucketName: "agero-am-dev-dashboard",
  DomainURL: "https://amdevdashboard.agero.com",
  TrainingDocsDomain: "https://dqpt9oamxigtc.cloudfront.net",
  userPersonaGroups: ["AMDCASECREATE-NOUSAA"],
  //link for AM DashBoard new Training site
  trainingURL: "https://info.agero.com/accident-training-portal",
};
