import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "../../../../environments/environment";
import { SplunkService } from "./splunk.service";
import { AuthService } from "./authentication.service";

@Injectable()
export class IpFilterService {
  public ipList = new BehaviorSubject(null);
  ipList$ = this.ipList.asObservable();

  constructor(
    private http: HttpClient,
    private splunkService: SplunkService,
    private authService: AuthService
  ) {}

  public getIpFilter(): Observable<any> {
    let programCode = this.authService.getDecodedIdToken().programCode;
    let token = this.authService.getRawIdToken();
    let logData = {
      /*send data to splunk*/
      logLevel: "Info",
      extraLog: "true",
      logDescription: "Inside the getIpfilter Method",
      logMessage: "token in ipfilter" + token,
    };
    this.splunkService.splunkLog(logData);
    const httpOptions = {
      headers: new HttpHeaders({
        userTriggerEvent: "After successful login ",
        hasAccountId: "false",
      }),
    };
    return this.http.get(
      environment.API.URL + `/api/${programCode}/ipFilter`,
      httpOptions
    );
  }
}
