import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LogoutComponent } from "./_modules/logout/logout.component";
import { CallBackComponent } from "./_modules/callback/callback.component";

const routes: Routes = [
  { path: "", redirectTo: "/dashboard/home", pathMatch: "full" },
  { path: "callback.html", component: CallBackComponent },
  {
    path: "dashboard",
    loadChildren: () => import('./_modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: { meta: { title: "Dashboard" } }
  },
  {
    path: "not-found",
    loadChildren: () => import('./_modules/not-found/not-found.module').then(m => m.NotFoundModule),
    data: { title: "Not Found" }
  },
  {
    path: "error",
    loadChildren: () => import('./_modules/error/error.module').then(m => m.ErrorModule),
    data: { title: "Error" }  
  },
  {
    path: "invalid-url",
    loadChildren: () => import('./_modules/invalid-url/invalid-url.module').then(m => m.InvalidUrlModule),
    data: { title: "Invalid URL" }
  },
  { path: "logout.html", component: LogoutComponent },

  { path: "**", redirectTo: "invalid-url" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
