import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "./authentication.service";

@Injectable()
export class ImpersonateService {
  public title = new BehaviorSubject(null);
  private programCode: string = this.authService.getDecodedIdToken()
    .programCode;
  public impersonateArray = [];
  constructor(private http: HttpClient, private authService: AuthService) {}

  public getClient(): Observable<any> {
    return this.http.get(
      environment.API.URL + `/api/${this.programCode}/lookups/getcompanylist`
    );
  }

  public impersonateChange(requestData: any, getImpersonateId: any) {
    return this.createOrUpdateAgeroUser(requestData, getImpersonateId);
  }

  public createOrUpdateAgeroUser(requestData: any, getImpersonateId: any) {
    let programCode = this.impersonateArray.find(
      (code) => code.id === getImpersonateId
    ).programCode;
    return this.http.post(
      environment.API.URL + `/api/${programCode}/ageroUsers`,
      requestData
    );
  }

  public setTitle(title: any) {
    this.title.next(title);
  }
  public setProgramCode() {
    let programCode = localStorage.getItem("currentProgramCode");
    if (programCode == null || programCode == undefined) {
      let clientArray = JSON.parse(
        this.authService.getDecodedIdToken().clientCodes
      );
      let clientCode = clientArray.indexOf(environment.clientCode.Agero);
      if (clientCode == -1) {
        /*not an agero user */
        localStorage.setItem(
          "currentProgramCode",
          this.authService.getDecodedIdToken().programCode
        );
      }
    }
  }
}
